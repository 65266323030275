.mainHome {
  flex: 1; /* Grow to fill remaining space */
  display: flex;
}

.content {
  flex: 1; /* Grow to fill remaining space */
  display: flex;
}
.leftColumn {
  flex: 0 0 70%;
  background-color: #9b1717; /* Just for demonstration */
  height: 80px;
}

.rightColumn {
  flex: 0 0 30%;
  background-color: #79b30d; /* Just for demonstration */
  height: 80px;
}

.additionalBlocks {
  display: flex;
  justify-content: space-between; /* Align blocks with equal spacing between them */
  margin-top: 0px; /* Adjust the margin as needed */
}
