@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");

* {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 10%;
}
.dontuse {
  background-color: #ffd703;
  background-color: #998622;
}
.mainHome {
  display: flex;
  height: 100vh;
}

.col1,
.col2,
.col3 {
  flex: 1; /* Both columns take 50% width */
  height: 100vh; /* Full viewport height for each column */
  align-items: center;
}

.col1 {
  background-color: #f6f6f6; /* Example color for left */
  border-radius: 3px; /* Adjust for desired curve */
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1),
    /* Subtle inset shadow */ 2px 2px 4px rgba(0, 0, 0, 0.1); /* Raised bottom shadow */
}
.col3 {
  background-image: url("images/truckonbridge.jpg"); /* Replace with your image path */
  background-size: cover; /* Fills the container, maintaining aspect ratio */
  background-position: center; /* Centers the image within the container */
  border-radius: 5px; /* Maintain rounded corners if desired */
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1); /* Optional subtle shadow */
}

.col2 {
  display: flex; /* Make col2 a flex container */
  flex-direction: column; /* Stack items vertically */
}
/* .leftColumn,
.rightColumn {
  flex: 50%;
  background-color: yellow; /* Example color for left */
/* background-color: #e0e0e0; /* Example color for right */
/* height: 80px; Adjust height as needed
} */
/* .content {
  flex: 1;
  display: flex;
} */
/* .col1 {
  flex: 0, 0, 50%;

  background-color: #e0e0e0; 
  max-width: 50%;
  height: 100%; 
}
.col2 {
  flex: 0, 0, 50%;

  max-width: 50%;
  height: 100%;
} */

.additionalBlocks {
  display: flex;
  justify-content: space-between; /* Align blocks with equal spacing between them */
  margin-top: 0px; /* Adjust the margin as needed */
}

.block1,
.block2,
.block3 {
  width: 30%; /* Each block takes up 30% of the container */
  background-color: #f0f0f0; /* Just for demonstration */
}

main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Ensure the main element takes up the full viewport height */
  width: 100%;
}

.mainPage {
  display: flex;
}

video {
  margin: 0;
  padding: 0;
  color: black;
  font: "Montserrat", sans-serif;
  width: 100%;
  height: 10%;
  object-fit: cover;
  margin-bottom: 0;
}

.content {
  color: black;
  font: "Montserrat", sans-serif;
  width: 100%;
  height: 10%;
  object-fit: cover;
}
.hrefStyle {
  display: flex;
  margin: auto;
  background-color: #ffffff;
  color: #998622;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  padding: 10px 20px;
  text-decoration: none;
  /* border: 1px solid #998622; Add a border for styling */
  border-radius: 2px;
}
.mainMenu {
  display: auto;
  margin: auto;
  background-color: #f6f6f6;
  color: #999999;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-size: 15px;
  font-weight: normal;
  padding: 10px 20px;
  text-decoration: none;
  /* border: 1px solid #998622; Add a border for styling */
  border-radius: 2px;
}

.secondnavbar {
  display: flex;
  align-items: center !important;
  background-color: #f6f6f6;
  color: #999999;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-size: 15px;
  font-weight: normal;
  padding: 5px 20px;
  text-decoration: none;
  position: sticky;
  flex-wrap: nowrap;
}

.nav-link {
  display: flex;
  align-items: center;
  background-color: #f6f6f6;

  color: #999999;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-size: 15px;
  font-weight: normal;
  padding: 5px 20px;
  text-decoration: none;
  position: sticky;
  flex-wrap: nowrap;
  position: relative;
  margin: 10px;
}

.nav-link.selected {
  color: #998622;
  font-weight: 550;
}
.nav-link .nav-image {
  width: 45px;
  height: 45px;
  background-image: url(../src/images/blackticklogo.png);
  background-size: cover; /* Adjust as needed */
  position: absolute;
  /* Adjust the positioning as needed */
  top: -14px; /* Initially position the image at the bottom */
  left: -25px; /* Align the image horizontally in the middle */
  transform: translateX(-50); /* Move the image outside of the navbar */
  transition: bottom 0.9s ease; /* Add transition effect */
  display: none; /* Hide the image by default */
}
.nav-link:hover .nav-image {
  bottom: 0; /* Show the image on hover */
  display: block; /* Show the image on hover */
}

.nav-link.selected .nav-image {
  bottom: 0; /* Show the image on hover */
  display: inline-block; /* Show the image on hover */
}

/* @media (max-width: 768px) {
  .secondnavbar {
    font-size: 13px; /* Decrease font size for smaller screens */
/* padding: 5px 10px; Decrease padding for smaller screens */
/* }
}  */

.iconContainer {
  color: #999999;
  /* margin-left: 40%; */
  height: 30px;
  display: flex;
  align-items: center;
}

/* .iconContainer:hover {
  color: #998622;
} */

.para {
  color: rgb(82, 82, 82);
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  text-align: center;
  padding: 10px 20px;
  margin: 20;
  line-height: 1.5;
  background-color: #f6f6f6;
  margin-top: 0;
  margin-bottom: 0;
}
/* Copied Code */

.main {
  width: 100%;
  height: 20%;
}
.overLay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
  font: "Montserrat", sans-serif;
}

.content {
  margin-top: 10%;
  margin-left: 8%;
  position: absolute;
  font-family: "Montserrat", sans-serif;
  font-size: 12;
  width: 30%;
  height: 25%;
  display: grid;
  justify-content: center;
  align-items: center;
  color: black;
  background-color: rgba(246, 208, 11);
  opacity: 0.7;
}

.button {
  /* color: white !important;
  font-size: 40;
  font: bolder;
  font-family: "Montserrat", sans-serif;
  background-color: black;
  width: 50;
  height: 70%;
  opacity: 1;
  border-radius: 5px;
  padding: 50;
  margin-left: 6px;
  margin-right: 6px;
  display: flex; */
  color: white;
  font-size: 24px; /* Adjust font size to make the text wrap into three lines */
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  background-color: black;
  width: 100%; /* Set button width to fill the container */
  height: auto; /* Allow button height to adjust based on content */
  opacity: 1;
  border-radius: 5px;
  padding: 28px; /* Adjust padding for better appearance */
  margin-top: 20px; /* Adjust margin for spacing */
  margin-left: 6px;
  margin-right: 6px;
  margin-bottom: 20px;
}

.SUtext {
  color: white;
  font: bold;
  font-family: "Raleway", arial, sans-serif;
  font-size: 36px;
  position: absolute;
  width: 50;
  height: 70%;
  margin-top: 8%;
  margin-left: 6%;
}
.containerImage {
  opacity: 0.8;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}

.containersOverlay {
  /* position: absolute;
  font-family: "Montserrat", sans-serif;
  font-size: 46px;
  width: 100%;
  height: 100%;
  /* display: grid; */
  /* justify-content: center;
  align-items: center;
  color: #ffd703;
  margin-top: 15%;
  object-fit: cover;
  text-align: center;
  text-shadow: #f6f6f6;
  box-shadow: #f6f6f6; * */

  position: absolute;
  font-family: "Montserrat", sans-serif;
  font-size: 46px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: white; /* Changed text color to white for better visibility */
  margin-top: 15%;
  object-fit: cover;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Added text shadow for better contrast */
  /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5); */
  font-weight: bolder;
}

.SUbutton {
  margin-top: 15px;
  margin-right: 0px;
  margin-left: 85px;
  color: black;
  font: bold;
  font-weight: 600;
  background-color: #998622;
  font-family: "Raleway", arial, sans-serif;
  position: absolute;
  width: 21%;
  height: 15%;
}

.myButton {
  /* Add your custom styles here */

  margin-top: 12%;
  padding-left: 50%;
  padding-right: 50;
  width: 250px;

  margin-left: 50%;
  margin-right: 50%;
  background-color: #ffd703; /* Green background */
  color: black; /* White text */
  text-decoration: solid;
  font-weight: bold;

  border: none; /* No border */
  padding: 10px 20px; /* Padding for better appearance */
  /* Center text */
  text-decoration: none; /* No underline */
  display: inline-block; /* Display as inline block */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Cursor pointer on hover */
  border-radius: 4px; /* Optional: Add border radius */
  position: absolute;
  font-family: "Raleway", arial, sans-serif;
}

.mainMenu.selected {
  color: #998622;
  font: bolder;
}

.pageTitles {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: rgb(173, 146, 2);
  text-align: center;
  margin-top: 0;
}

.secondaryTitles {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: rgb(173, 146, 2);
  text-align: center;
  margin-top: 12px;
}
.OTPTitles {
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  color: black;
  text-align: center;
  margin-top: 0;
}

.aboutPageContent {
  font-family: "Montserrat", sans-serif;
  font: size 10px;
  font-weight: normal;
  color: rgb(87, 87, 87);
  text-align: center;
  margin-left: 30%;
  margin-right: 30%;
  justify-content: center;
}

.contactUs {
  color: rgb(173, 146, 2);
  text-align: center;
}

.footr {
  padding-top: 1%;
  padding-bottom: 1%;
  font-family: "Raleway", sans-serif;

  width: 100%;
  font-size: 14px;
  /* display: block; */
  letter-spacing: normal;
  text-align: center;
  background-color: #f6f6f6;
  margin-top: 0;
  /* color: rgb(82, 82, 82); */
  color: #999999;
  margin-bottom: 0%;
  position: fixed;
  vertical-align: bottom;
  bottom: 0;
  padding: "10px";
  /* height: 25%; */
}

/* position: "fixed",
bottom: 0,
width: "100%",
backgroundColor: "#f8f9fa",
padding: "10px",
textAlign: "center", */
.contactUsBttn {
  /* Add your custom styles here */
  width: 40%;
  height: 25%;
  background-color: #ffd703; /* Green background */
  color: black; /* White text */
  text-decoration: solid;
  font-weight: bold;
  border: none; /* No border */
  /* Padding for better appearance */
  /* Center text */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Cursor pointer on hover */
  border-radius: 4px; /* Optional: Add border radius */
  position: flex;
  font-family: "Raleway", arial, sans-serif;
  font-size: large;
  /* align-items: center;
 align-content: center;
 align-self: center;
 vertical-align: center;
 text-align: center; */
}
.form {
  width: 35%;
  align-items: center;
  border-style: groove;
  align-content: left;
  align-self: center;
  vertical-align: center;
  text-align: left;
  padding: 12px 20px;
  font-family: "Raleway", arial, sans-serif;
  border-radius: 4px;
  font-size: large;
}

.formDiv {
  align-items: center;
  align-content: center;
  vertical-align: center;
  text-align: center;
  align-tracks: center;
  margin-bottom: 60px;
}
.textArea {
  height: 1000px;
  resize: none;
  /* border-style: groove; */
  font-family: "Raleway", arial, sans-serif;
  border-radius: 4px;
  font-size: large;

  /* font-family: "Nunito", sans-serif; */
  font-size: 22px;
  padding: 0.25rem;
  border-radius: 0.5rem;
  appearance: none;
  width: 100%;
}

.textBox {
  height: 2500px;
  /* border-style: groove; */
  font-family: "Raleway", arial, sans-serif;
  border-radius: 4px;
  font-size: large;

  /* font-family: "Nunito", sans-serif; */
  font-size: 22px;
  padding: 0.25rem;
  border-radius: 0.5rem;
  appearance: none;
  width: 100%;
}

.messageBttn {
  /* Add your custom styles here */
  width: 150px;
  height: 60px;
  background-color: #ffd703; /* Green background */
  color: black; /* White text */
  text-decoration: solid;
  font-weight: bold;
  border: none; /* No border */
  /* Padding for better appearance */
  /* Center text */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Cursor pointer on hover */
  border-radius: 4px; /* Optional: Add border radius */
  position: flex;
  font-family: "Raleway", arial, sans-serif;
  font-size: large;
  /* align-items: center;
  align-content: center;
  align-self: center;
  vertical-align: center;
  text-align: center; */
}

.textArea {
  width: 35%;
  height: 300px;
  resize: none;
  padding: 12px 20px;
  border-style: groove;
  font-family: "Raleway", arial, sans-serif;
  border-radius: 4px;
  font-size: large;
}

.trailingLights {
  width: 100%;
  max-height: 15%;
  height: 15%;
  margin-top: 0;
  margin-bottom: 0;
}

.payNowBttn {
  color: black;
  background-color: #ffd703;
  text-decoration: solid;
  font-weight: bold;
  border: none;
  text-decoration: solid; /* No underline */
  display: inline-block; /* Display as inline block */
  font-size: 20px; /* Font size */
  cursor: pointer; /* Cursor pointer on hover */
  border-radius: 4px; /* Optional: Add border radius */
  /* position: absolute; */
  font-family: "Raleway", arial, sans-serif;
  padding: 10px;
  border-radius: 4px;
}

.brokerBlock {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.socialIcons {
  width: 14px;
  height: 14px;
}

.brokercard {
  background-color: #f9f9f9;
  color: black;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 35%;
  margin: 0 auto; /* This will center the card horizontally */
  line-height: 0.5;
}

.card .title {
  text-align: center;
  color: #998622;
}

.centered-card .cardheader {
  font-weight: bolder;
  text-decoration: underline;
  margin-right: 3px;
  color: #999999;
  font-style: normal;
}

span {
  font-style: italic;
}
.headerImage {
  /* width: 30%; */
  /* aspect-ratio: 3/2; */
  object-fit: contain;
  mix-blend-mode: color-burn;
  position: relative;
}

.hrefStyle2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  color: #998622;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 2px;
  box-sizing: border-box;
}

/* This is only used as a test. OK to Delete late */
.headerImage2 {
  width: "108px";
  height: "auto";
  display: "flex";
  margin: "auto";
  background-color: #ffffff;
  color: "#998622";
  font-style: "Montserrat, sans-serif";
  text-align: "center";
  font-size: "28px";
  font-weight: "600";
  padding: "10px 20px";
  text-decoration: "none";
  border: "1px solid #998622";
  border-radius: "2px";
  object-fit: contain;
  mix-blend-mode: color-burn;
  position: relative;
}
.word {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  margin-right: 3%;
  margin-left: 3%;
  margin-top: 1%;
  margin-bottom: 0%;
}

.definition {
  font-family: "Montserrat", sans-serif;
  font-weight: lighter;
  margin-right: 3%;
  margin-left: 4.5%;
  margin-top: 0.1%;
  margin-bottom: 1%;
}

#letterLinks a {
  font-size: larger !important;
  color: #999999 !important;
  font-weight: bold;

  margin-right: 0.5% !important;
  text-decoration: none !important;
}

#letterLinks {
  text-align: center !important;
  color: #999999 !important;
  position: sticky !important;
  top: 0;
  background-color: white !important;
  padding-bottom: 25px;
  border-style: outset;
  border-top: none;
}

footer {
  padding-top: 1%;
  padding-bottom: 2%;
  font-family: "Raleway", sans-serif;
  width: 100%;
  font-size: 16px;
  letter-spacing: normal;
  background-color: #f6f6f6;
  color: #999999;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex; /* Use flexbox to align items */
  justify-content: center; /* Align items evenly */
  align-items: center;
  background-color: #f6f6f6;
}
.socialmediacontainer {
  margin: 5px;
  text-align: center;
  margin-top: 11%;
  padding-top: 1.5%;
  margin-left: 12%;
  background-color: #f6f6f6;
}

.ibexLink {
  margin: 5px;
  text-align: center;
  background-color: #f9f9f9;

  color: #999999;
}
.ibexLink:visited {
  color: #999999;
}
.ibexLink:active {
  color: rgba(245, 135, 50, 255);
}
.ibexLink:hover {
  color: rgba(245, 135, 50, 255);
}

.copyrighttxt {
  margin: 5px;
  text-align: center;
  background-color: #f6f6f6;
}
.overview {
  text-justify: distribute;

  margin: 25px;
  font-family: "Montserrat", sans-serif;
  font-size: 35;
  font-weight: normal;
  color: rgb(87, 87, 87);
  text-align: justify;
  margin-left: 30px;
  margin-right: 30px;
  justify-content: space-around;
}

.FAQ {
  background-color: #f6f6f6; /* Background color */
  /* border: 1px solid #ccc; Border */
  border-radius: 2px; /* Rounded corners */
  padding: 20px; /* Padding inside the box */
}
.customAccordionItem.selected {
  background-color: #f6f6f6 !important;
}
.customAccordionItem .accordion-button.collapsed {
  background-color: #f6f6f6 !important;
}

.customAccordionItem .accordion-button:not(.collapsed) {
  background-color: #f6f6f6 !important;
}

.form-container {
  width: 65%;
  display: grid;
  place-items: center;
  margin-bottom: 12px;
}

.form-group {
  margin-bottom: 20px; /* Spacing between form groups */
}

.myform-label {
  /* Ensure labels are on their own line */
  font-weight: lighter;
  margin-bottom: 0;
  text-align: left;
  align-items: left;
  align-self: left;
}

.form-input {
  width: 55%; /* Change this to match the width of the parent container */
  max-width: 100%; /* Ensure the input fields don't exceed the width of the parent container */
  padding: 8px;
  border: 2.5px solid #ccc;
  border-radius: 4px;
  margin-top: 0 !important;
  margin: 15px;
  padding: 8px;
}

.form-button {
  width: 100%; /* Take up full width of parent container */
  padding: 10px;
  max-width: 300px; /* Limit width to prevent stretching */

  background-color: rgba(246, 208, 11); /* Example button color */
  color: black; /* Button text color */
  font-weight: bolder;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-button:hover {
  transition: all 2s;
  background-color: #998622; /* Example hover color */
}

.glossary-link {
  position: sticky;
  top: 0;
}

/* @media (width >= 500px) 
 */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.navbar-links {
  display: none;
}

.navbar-links.active {
  display: block;
}

.navbar-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar-links ul li {
  display: inline-block;
  margin-right: 20px;
}

.navbar-toggle {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  background-color: #333;
}

.navbar-icon {
  display: block;
  width: 20px;
  height: 2px;
  background-color: #333;
}

/* Media Query for responsive design */
@media screen and (max-width: 768px) {
  .navbar-links {
    display: none;
  }

  .navbar-links.active {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    width: 100%;
    text-align: center;
    border-top: 1px solid #ccc;
    z-index: 999;
    background-color: #999999;
  }

  .navbar-links ul {
    display: block;
  }

  .navbar-links ul li {
    display: block;
    margin: 10px 0;
  }
}

.navbar-collapse {
  background-color: whitesmoke;
  justify-content: center;
}

/* dg */

/* break point */
.registersection {
  margin-top: 15px;
  width: 100%;
  max-width: 420px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
  background-color: #f6f6f6;
  align-self: center;
  border-radius: 0.7rem;
}
.content-below {
  /* Adjust margin or padding to ensure it's not covered by .registersection */
  margin-top: 100px; /* Adjust this value based on your layout */
}

.pass {
  /* display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
  padding-bottom: 1rem; */

  font-family: "Nunito", sans-serif;
  font-size: 22px;
  padding: 0.25rem;
  border-radius: 0.5rem;
  appearance: none;
  position: relative;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
  padding-bottom: 1rem;
}

/* a,
a:visited {
  color: #fff;
} */

input.number-to-text::-webkit-outer-spin-button,
input.number-to-text::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"].number-to-text {
  -moz-appearance: textfield;
}

input[type="text"],
input[type="password"],
input[type="number"],
input[type="email"],
button {
  font-family: "Nunito", sans-serif;
  font-size: 19px;
  padding: 0.25rem;
  border-radius: 0.5rem;
  appearance: none;
  border-color: #f6f6f6;
}
/* textarea {
  font-family: "Nunito", sans-serif;
  font-size: 19px;
  padding: 0.25rem;
  border-radius: 0.5rem;
  appearance: none;
  height: 600px;
  width: 550px;
} */

.textBox {
  font-family: "Nunito", sans-serif;
  font-size: 19px;
  padding: 0.25rem;
  border-radius: 0.5rem;
  appearance: none;
  height: 600px;
  width: 550px;
  appearance: none;
}
.searchBox {
  font-family: "Nunito", sans-serif;
  font-size: 19px;
  padding: 0.25rem;
  border-radius: 0.5rem;
  appearance: none;
  width: 550px;
  appearance: none;
  margin-bottom: 15px;
}

button {
  margin-top: 1rem;
  /* display: flex;
  align-items: center;
  line-height: 25px; */
}

button {
  background-color: #ffd703;
  font-family: "Nunito", sans-serif;
  font-size: 19px;
  padding: 0.25rem;
  border-radius: 0.5rem;
  appearance: none;
  border-color: #998622;
}

.instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  background: #000;
  color: #fff;
  padding: 0.25rem;
  position: relative;
  bottom: -10px;
}

.instructions > svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  color: green;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.line {
  display: inline-block;
}
/* .footer-col {
  width: auto;
  align-items: center;
  align-content: center;
  text-align: center;
  margin-bottom: 12px;
} */

/* .footer-col {
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  width: auto;
} */

.footer-col {
  padding-top: 16px;
  display: table-cell; /* Use table-cell */
  vertical-align: middle; /* Vertically center content */
  width: auto;
  text-align: center; /* Horizontally center content */
}

.footer-col-Ibex {
  display: table-cell; /* Use table-cell */
  vertical-align: middle; /* Vertically center content */
  width: auto;
  text-align: center; /* Horizontally center content */
  margin-bottom: 15px;
  padding-bottom: 15px;
  margin-left: -150px;
}

.footer-col-text {
  display: table-cell; /* Use table-cell */
  vertical-align: middle; /* Vertically center content */
  width: auto;
  text-align: center; /* Horizontally center content */
}

.notification {
  position: center;
  text-align: center;
  position: relative;
  background-color: rgba(
    246,
    246,
    246,
    0.8
  ); /* Adjust the alpha value for transparency */
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1),
    inset 0 0 20px rgba(255, 255, 255, 0.5); /* Add a glass-like box-shadow */
  width: 75%;
}

.close-button {
  position: relative; /* Position the close button relative to the notification */
  top: 5px; /* Adjust top position */
  right: 5px; /* Adjust right position */
  background: none; /* Remove background */
  border: none; /* Remove border */
  font-size: 20px; /* Adjust font size */
  cursor: pointer; /* Change cursor to pointer */
}

.close-button:hover {
  color: #ff1a1a;
}

.message {
  display: inline-block;
  color: yellow;
}
.alert {
  color: #ff1a1a;
}
/* 
.alert {
  color: #ff1a1a;
}
 */

.ReviewCard {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.DisplayDataContainer {
  flex: 0 0 70%; /* Takes up 70% of the width */
  max-width: 70%;
}

.PieChartContainer {
  flex: 0 0 30%; /* Takes up 30% of the width */
  max-width: 30%;
}
.SideBySideContainer {
  display: flex;
  justify-content: space-between; /* Ensures there is space between the two components */
  align-items: flex-start; /* Aligns items to the top */
}

/* .SearchInputContainer {
  text-align: center;
}

.SearchInput {
  font-family: "Nunito", sans-serif;
  font-size: 22px;
  padding: 0.25rem;
  border-radius: 0.5rem;
  appearance: none;
} */

.SearchInputContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 10px; /* Adjust as needed */
}

.SearchInput {
  width: 100%;
  max-width: 300px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.pagination {
  display: flex;
  justify-content: center; /* Centers the pagination */
  list-style: none;
  padding: 0;
  margin: 20px 0; /* Optional: Add margin to adjust spacing */
}

.page-item {
  margin: 0 5px;
}

.page-link {
  padding: 5px 10px;
  border: 1px solid #ccc;
  color: #ccc;
  text-decoration: none;
  cursor: pointer;
}

.page-link:hover {
  background-color: #ccc;
  color: white;
}

.page-item.active .page-link {
  background-color: #ccc; /* Change this to your desired color */
  color: white;
  border: 1px solid #ccc; /* Match this to the background color */
}
.page-item.active {
  background-color: #ccc; /* Change this to your desired color */
  color: white;
  border: 1px solid #ccc; /* Match this to the background color */
}

.brokerinfo {
  position: sticky;
}

.centered-text {
  display: block;
  text-align: center;
  margin: 10px 0; /* Optional: adjust margin for spacing */
}

.review-item {
  list-style-type: none;
  margin-bottom: 10px;
}

.review-subheader {
  /* display: inline-block; Makes it a block-level element */
  width: 150px; /* Adjust this width as necessary */
  font-weight: bold;
  text-align: left;
  vertical-align: top; /* Aligns with the top of the paragraph */
}

.review-text {
  /* display: inline-block; Makes it inline with the subheader */
  margin-left: 10px;
  padding: 10px; /* Add padding to prevent text from touching the scrollbar */
  text-align: justify;
  max-width: 80%; /* Ensure it does not take up the entire width */
  box-sizing: border-box; /* Include padding in the element's total width and height */
  word-wrap: break-word; /* Break long words */
}

ul {
  padding: 0; /* Removes default padding from ul */
}

hr {
  border: 0;
  border-top: 1px solid #999999;
  margin: 10px 0;
}

.reviewsection {
  margin-top: 15px;
  width: 100%;
  max-width: 820px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
  background-color: #f6f6f6;
  align-self: center;
  border-radius: 0.7rem;
}

.radioButton {
  align-self: center;
  margin: 12px;
  font-family: "Raleway", sans-serif;
  font-size: large;
  justify-content: flex-start;
  font-size: medium;
}

.legend {
  align-self: center;
  margin-left: 5px;
  font-family: "Raleway", sans-serif;
  font-size: medium;
}

.email-container {
  display: flex; /* Make the container a flex container */
  flex-direction: column; /* Stack items vertically */
  justify-content: center;
  display: inline-block;
  text-align: center;
  height: 1000px;
}
.emailAddress {
  font-size: x-large;
  font: "Montserrat", sans-serif;
  display: inline-block; /* Make it an inline-block element */
  margin: 0 auto; /* Center horizontally */
  text-align: center;
}
.centered-page {
  margin-top: 15px;
  width: 100%;
  max-width: 850px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
  background-color: #f6f6f6;
  align-self: center;
  border-radius: 0.7rem;

  margin: 0 auto;
  text-align: center;
  margin-right: auto;
}

.centered-search {
  text-align: center;
}
.centered-card {
  margin: 0 auto;
}

/* .verticalLine {
  height: 25px; 
  width: 1px;
  background-color: black; 
  margin: 0 10px; 
} */

.radioLabel {
  margin-right: 15px;
}

.advertiseBanner {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffd703; /* Background color for visibility */
  color: black; /* Text color for contrast */
  font-family: "Montserrat", sans-serif; /* Ensuring consistent font usage */
  font-size: 18px; /* Adjust font size as needed */
  position: relative;
}

.advertiseBanner p {
  width: 100%; /* Make the paragraph take the full width */
  text-align: center; /* Center the text horizontally */
  margin: 0; /* Remove default margins */
  font-weight: bolder;
}
.tagline {
  text-align: center;
  font-weight: bold;
  font-family: "Times New Roman", Times, serif; /* Newspaper style font */
  font-size: 24px; /* Adjust the font size as needed */
  margin-bottom: 20px; /* Adds margin to prevent it from hiding behind the footer */
  background-color: #f1f1f1; /* Light background to mimic newspaper */
  padding: 10px; /* Padding for better spacing */
  border: 1px solid #ccc; /* Border to give it a defined box */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  /* font-family: "Amatic SC", cursive; */
  font-size: xx-large;
  font-weight: bolder;
  color: #f55656;
  font-family: "Permanent Marker", cursive;
}

.taglinebackground {
  background-image: url("images/wrinkledpaper.jpeg"); /* Replace with your image path */
  background-size: 60%; /* Fills the container, maintaining aspect ratio */
  background-position: center; /* Centers the image within the container */
  border-radius: 5px; /* Maintain rounded corners if desired */
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1); /* Optional subtle shadow */
}
/* 
.appContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.appcontent {
  flex: 1;
} */
.footer-top {
  margin-top: 50px;
}

.user-profile {
  background-color: #f6f6f6; /* Example color for left */
  border-radius: 3px; /* Adjust for desired curve */
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1), 2px 2px 4px rgba(0, 0, 0, 0.1); /* Raised bottom shadow */
  padding: 20px;
  max-width: 400px;
  margin: 20px auto; /* Center horizontally */
  text-align: center; /* Center text */
  width: 500px; /* Fixed width */
  height: 300px; /* Fixed height */
}

.user-name {
  font-size: 20px;
  font-weight: bolder;
  margin: 0px 0; /* Add some space around the name */
}

.user-email {
  font-size: 18px;
  color: #666;
  margin: 0;
  margin-top: -5px;
  margin-bottom: 5px;
}

.centeredtext {
  text-align: center;
}

.register-page {
  align-self: center;
  margin-bottom: 12%;
}

.legal-container {
  display: contents; /* Set container as flexbox */
  justify-content: center; /* Center content within the container */
  align-self: center;
}

.legal-text {
  font-family: monospace; /* Or a suitable font for legal text */
  font-size: 12px;
  line-height: 1.5;
  color: #666;
  text-align: justify; /* Justify text within the container */
  justify-content: center;
  margin-left: 30px; /* Add margin to separate from header */
  margin-right: 30px;
  margin-bottom: 40px;
}
.legal-text strong {
  font-family: monospace; /* Or a suitable font for legal text */
  font-size: 12px;
  line-height: 1.5;
  color: #666;
  /* text-align: justify; Justify text within the container */
  /* justify-content: center;
  margin-left: 30px; /* Add margin to separate from header */
  /* margin-right: 30px; */
  font-weight: bold;
}

.legal-text h2 {
  font-family: "Courier New", Courier, monospace;
  font-size: 16px; /* Adjusted for better readability */
  line-height: 1.5;
  color: #666;
  font-weight: 700; /* Ensures the header stands out */
  margin-top: 15px;
  margin-bottom: 0px;
}

.legal-header {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 25px;
  margin-top: 25px;

  justify-content: center; /* Center content within the container */
  align-self: center;
}

/* New style for sibling selector */
.legal-container > .legal-header {
  margin-right: 20px;
  margin-left: 20px; /* Add margin to header for separation */
}

/* styles.css */

/* Center the notification on the page */
.notification-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Optional: adds a dark overlay */
  z-index: 1000; /* Ensure it appears on top of everything else */
}

/* Style for the notification box */
.notification {
  background-color: #ffcccc; /* Light red background */
  color: #990000; /* Dark red text color */
  font-size: 20px; /* Larger font size */
  text-align: center; /* Center the text */
  padding: 20px; /* Add padding for spacing */
  border-radius: 5px; /* Rounded corners */
  max-width: 400px; /* Limit width of the box */
  margin: 0 20px; /* Ensure some margin on smaller screens */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
}

/* Style the close button */
.notification button {
  display: block; /* Make button block-level */
  margin: 20px auto 0 auto; /* Center the button */
  padding: 10px 20px; /* Add padding for button */
  background-color: #990000; /* Dark red button color */
  color: #ffffff; /* White text */
  border: none; /* Remove default border */
  border-radius: 3px; /* Slight rounding */
  cursor: pointer; /* Pointer cursor */
  font-size: 16px; /* Slightly larger text */
}

.notification button:hover {
  background-color: #cc0000; /* Darker red on hover */
}
