@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap");

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

.body {
  font-family: "Montserrat", sans-serif;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.errorContainer {
  margin-top: -20%;
  text-align: center;
  background-color: whitesmoke;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.h1 {
  font-size: 5rem;
  /* color: #ff5733; */
  color: #ffd400;
}

.p {
  font-size: 1.5rem;
  color: #000000;
  margin-bottom: 20px;
}

.a {
  text-decoration: none;
  /* background-color: #ff5733; */

  background-color: #ffd400;
  color: #fff;
  padding: 10px 20px;
  border-radius: 3px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;

  /* transition: background-color 0.3s ease; */
}

.a:hover {
  /* background-color: #e6482e; */
  background-color: #ffd400;
}
